import { openBlock as _openBlock, createBlock as _createBlock } from "vue"

import SignContract from '../components/Contract.vue';


export default {
  setup(__props) {


return (_ctx, _cache) => {
  return (_openBlock(), _createBlock(SignContract, {
    detailRouter: "/user/contract/detail",
    contractType: "2"
  }))
}
}

}